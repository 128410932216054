<template>
  <div class="help-page d-flex align-items-center">
    <form action="">
      <div class="mb-4">
        <label for="text" class="form-label">What do you need?</label>
        <div class="input-group">
          <textarea name="" id="" cols="30" rows="5" id="text" class="form-control"
                    placeholder="Tell us what help do you need ?"></textarea>
        </div>
      </div>
      <div class="mb-3">
        <div class="d-flex align-items-center">
          <button class="save-btn red" type="submit">Submit</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "Help"
}
</script>

<style scoped>

</style>